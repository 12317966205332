import moment from 'moment';
import React from 'react';
import { useData } from '../../../contexts/data.context';
import { getTreatmentEnd } from '../../../helper/insulin-duration.helper';
import FoodModel from '../../../models/dtos/food.model';
import ReadingModel from '../../../models/dtos/reading.model';
import BolusModel from '../../../models/dtos/treatment.model';
import SegmentModel from '../../../models/segment.model';
import BasalSegmentView from '../BasalSegmentView/BasalSegmentView';
import './BasalCurrentDateView.scss';

interface BasalCurrentDateViewProps {
    readings: ReadingModel[];
    treatments: BolusModel[];
    foods: FoodModel[];
}

function BasalCurrentDateView(props: BasalCurrentDateViewProps): JSX.Element {
    const { segmentCount, insulinDuration } = useData();

    const addMinutes = (date: Date, minutes: number) => {
        var currentDayMoment = moment(date);
        currentDayMoment.add('minutes', minutes);
        return currentDayMoment.toDate();
    };

    const getTimeSegments = () => {
        if (props.readings.length === 0) {
            return [];
        }
        const startOfDay = moment(props.readings[0].localDate)
            .startOf('day')
            .toDate();
        const segmentMinutes = segmentCount === 16 ? 90 : 60;
        let startOfSegmentLoop = moment(props.readings[0].localDate)
            .startOf('day')
            .toDate();
        const segments: SegmentModel[] = [];

        const sortedByGlucoseLevel = [...props.readings];
        sortedByGlucoseLevel.sort((a, b) => a.glucoseValue - b.glucoseValue);

        while (moment(startOfSegmentLoop).isSame(moment(startOfDay), 'day')) {
            const startOfSegment = moment(startOfSegmentLoop).toDate();
            const endOfSegment = addMinutes(startOfSegmentLoop, segmentMinutes);

            const readingsOfSegment = props.readings.filter(
                (reading) =>
                    reading.localDate >= startOfSegment &&
                    reading.localDate < endOfSegment
            );
            const treatmentsOfSegment = props.treatments.filter((treatment) => {
                const treatmentEnd = getTreatmentEnd(
                    treatment,
                    insulinDuration
                );

                return !(
                    treatmentEnd < startOfSegment ||
                    treatment.localDate > endOfSegment
                );
            });

            const foodsOfSegment = props.foods.filter((food) => {
                return (
                    startOfSegment <= food.localDate &&
                    endOfSegment >= food.localDate
                );
            });

            let minGlucoseValue =
                sortedByGlucoseLevel.length > 0
                    ? sortedByGlucoseLevel[0].glucoseValue
                    : 30;

            const maxGlucoseValue =
                sortedByGlucoseLevel.length > 0
                    ? sortedByGlucoseLevel[sortedByGlucoseLevel.length - 1]
                          .glucoseValue + 15
                    : 200;

            const barHeight = (maxGlucoseValue - minGlucoseValue) / 4 + 10;
            minGlucoseValue -= barHeight;
            segments.push({
                start: startOfSegmentLoop,
                end: endOfSegment,
                readings: readingsOfSegment,
                treatments: treatmentsOfSegment,
                foods: foodsOfSegment,
                minValue: minGlucoseValue,
                maxValue: maxGlucoseValue,
            });

            startOfSegmentLoop = endOfSegment;
        }

        return segments;
    };

    const segments = getTimeSegments();

    return (
        <div className="current-date-view">
            <h2>Basalratenanalyse</h2>
            <ul>
                {segments.map((segment) => (
                    <li key={segment.start.toString()}>
                        <BasalSegmentView segment={segment} />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default BasalCurrentDateView;
