import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from '../../../components/AnalyseDatePicker/AnalyseDatePicker';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { useData } from '../../../contexts/data.context';
import ReadingModel from '../../../models/dtos/reading.model';
import BolusModel from '../../../models/dtos/treatment.model';
import NightscoutService from '../../../services/nightscout.service';
import CorrectionCurrentDateView from './../CorrectionCurrentDateView/CorrectionCurrentDateView';
import './CorrectionDateAnalyse.scss';

function CorrectionDateAnalyse() {
    const [readings, setReadings] = useState<ReadingModel[]>([]);
    const [treatments, setTreatments] = useState<BolusModel[]>([]);
    const [date, setDate] = useState<Date | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const { apiSecret, url } = useData();
    const navigate = useNavigate();

    useEffect(() => {
        if (!url || url === '') {
            navigate('/settings');
        }
    }, []);

    const loadDateValues = async (date: Date) => {
        setIsLoading(true);
        const nightscoutService = new NightscoutService(url, apiSecret);
        const readingDtos = await nightscoutService.getReadingsForDay(date, 2);
        const treatmentDtos =
            await nightscoutService.getTreatmentsForBolusAnalyse(date);

        let readingModels: ReadingModel[] = readingDtos.map((reading) => {
            return {
                localDate: moment(reading.dateString).local().toDate(),
                glucoseValue: reading.sgv,
            };
        });

        let bolusModels: BolusModel[] = treatmentDtos.map((treatment) => {
            return {
                localDate: moment(treatment.created_at).local().toDate(),
                insulin: treatment.insulin,
                duration: treatment.duration,
                carbs: treatment.carbs,
            };
        });

        readingModels = readingModels.sort((a, b) => {
            return a.localDate.getTime() - b.localDate.getTime();
        });
        bolusModels = bolusModels.sort((a, b) => {
            return a.localDate.getTime() - b.localDate.getTime();
        });

        setReadings(readingModels);
        setTreatments(bolusModels);
        setDate(date);
        setIsLoading(false);
    };

    const dateChanged = async (date: Date) => {
        await loadDateValues(date);
    };

    if (!url || url === '') {
        return <></>;
    }

    return (
        <>
            <DatePicker dateChanged={dateChanged}></DatePicker>
            {!isLoading && (
                <CorrectionCurrentDateView
                    readings={readings}
                    treatments={treatments}
                    date={date}
                ></CorrectionCurrentDateView>
            )}
            {isLoading && <LoadingSpinner />}
        </>
    );
}

export default CorrectionDateAnalyse;
