import { Alert, Snackbar } from '@mui/material';
import React, { FunctionComponent, useContext, useState } from 'react';

export const ErrorContext = React.createContext<
    [
        string | undefined,
        React.Dispatch<React.SetStateAction<string | undefined>>
    ]
>({} as any);

export const ErrorProvider: FunctionComponent = (props) => {
    const errorMessageState = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = errorMessageState;
    return (
        <ErrorContext.Provider value={errorMessageState}>
            {props.children}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={!!errorMessage}
                autoHideDuration={5000}
                message={errorMessage}
                onClose={() => setErrorMessage(undefined)}
            >
                <Alert severity="error" elevation={6} variant="filled">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </ErrorContext.Provider>
    );
};

export const useError = () => {
    const context = useContext(ErrorContext);

    if (!context) throw new Error('useError must be inside a ErrorProvider');

    return context;
};
