import React from 'react';
import { Link } from 'react-router-dom';
import './Navigation.scss';

interface NavigationProps {
    linkClicked?: () => void;
}

function Navigation(props: NavigationProps): JSX.Element {
    return (
        <nav>
            <ul>
                <li>
                    <Link
                        to="/basalanalyse"
                        onClick={
                            props.linkClicked ? props.linkClicked : () => {}
                        }
                    >
                        Basalanalyse
                    </Link>
                </li>
                <li>
                    <Link
                        to="/bolusanalyse"
                        onClick={
                            props.linkClicked ? props.linkClicked : () => {}
                        }
                    >
                        Bolusanalyse
                    </Link>
                </li>
                <li>
                    <Link
                        to="/correctionanalyse"
                        onClick={
                            props.linkClicked ? props.linkClicked : () => {}
                        }
                    >
                        Korrekturanalyse
                    </Link>
                </li>
            </ul>
        </nav>
    );
}

export default Navigation;
