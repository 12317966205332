import { CircularProgress } from '@mui/material';
import React from 'react';
import './LoadingSpinner.scss';

function LoadingSpinner(): JSX.Element {
    return (
        <div className="loading-spinner">
            <CircularProgress />
        </div>
    );
}

export default LoadingSpinner;
