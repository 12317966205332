import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useData } from '../../contexts/data.context';
import FoodModel from '../../models/dtos/food.model';
import ReadingModel from '../../models/dtos/reading.model';
import BolusModel from '../../models/dtos/treatment.model';
import NightscoutService from '../../services/nightscout.service';
import AnalyseChart from '../AnalyseChart/AnalyseChart';
import './DayView.scss';

interface DayViewProps {
    date: Date;
    startHighlight: Date;
    endHighlight: Date;
}

function DayView(props: DayViewProps): JSX.Element {
    const { apiSecret, url } = useData();
    const [usableReadings, setUsableReadings] = useState<ReadingModel[]>([]);
    const [treatments, setTreatments] = useState<BolusModel[]>([]);
    const [foods, setFoods] = useState<FoodModel[]>([]);

    const start = moment(props.date).startOf('day').toDate();
    const end = moment(props.date).endOf('day').toDate();

    useEffect(() => {
        loadChartData();
    }, []);

    const loadChartData = async () => {
        const nightscoutService = new NightscoutService(url, apiSecret);

        const readingDtos = await nightscoutService.getReadingsForDay(
            props.date,
            1
        );
        let readingModels: ReadingModel[] = readingDtos.map((reading) => {
            return {
                localDate: moment(reading.dateString).local().toDate(),
                glucoseValue: reading.sgv,
            };
        });
        const usableReadingsTemp = readingModels.filter(
            (x: any) =>
                x.glucoseValue > 20 &&
                x.localDate >= start &&
                x.localDate <= end
        );
        usableReadings.sort(
            (a, b) => a.localDate.getTime() - b.localDate.getTime()
        );
        setUsableReadings(usableReadingsTemp);

        const treatmentDtos = await nightscoutService.getTreatmentsForDay(
            props.date
        );
        let treatmentModels: BolusModel[] = treatmentDtos.map((treatment) => {
            return {
                localDate: moment(treatment.created_at).local().toDate(),
                insulin: treatment.insulin,
                duration: treatment.duration,
                carbs: treatment.carbs,
            };
        });
        setTreatments(treatmentModels);

        const foodModels: FoodModel[] = treatmentModels
            .filter((x) => x.carbs)
            .map((x) => {
                return {
                    localDate: x.localDate,
                    carbs: x.carbs,
                };
            });
        setFoods(foodModels);
    };

    if (usableReadings.length === 0) {
        //TODO: empty message
        return <></>;
    }

    const readingsSortedByValue = [...usableReadings];
    readingsSortedByValue.sort((a, b) => a.glucoseValue - b.glucoseValue);

    const minGlucoseValue = readingsSortedByValue[0].glucoseValue;
    const maxGlucoseValue =
        readingsSortedByValue[readingsSortedByValue.length - 1].glucoseValue;

    return (
        <div className="day-view-container">
            <h2>{moment(props.date).format('DD.MM.yyyy')}</h2>
            <AnalyseChart
                displayXLabels={true}
                startHighlight={props.startHighlight}
                endHighlight={props.endHighlight}
                hideLines={true}
                chartModel={{
                    start: start,
                    end: end,
                    maxValue: maxGlucoseValue,
                    minValue:
                        minGlucoseValue -
                        (maxGlucoseValue - minGlucoseValue) / 2,
                    usableReadings,
                    treatments,
                    foods,
                }}
            ></AnalyseChart>
        </div>
    );
}

export default DayView;
