import { Alert } from '@mui/material';
import React from 'react';
import './OverlappingTreatmentAlert.scss';

function OverlappingTreatmentAlert(): JSX.Element {
    return (
        <Alert severity="warning" className="overlapping-alert">
            In diesem Analysezeitraum befinden sich andere Ereignisse, die sich
            auf den BZ auswirken. Die Analyse ist daher nicht aussagekräftig.
        </Alert>
    );
}

export default OverlappingTreatmentAlert;
