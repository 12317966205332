import { Alert } from '@mui/material';
import React from 'react';
import './NotAllReadingsForSegmentAlert.scss';

function NotAllReadingsForSegmentAlert(): JSX.Element {
    return (
        <Alert severity="warning" className="not-all-readings-alert">
            In diesem Analysezeitraums fehlen BZ Messwerte. Die Analyse ist
            daher nicht aussagekräftig.
        </Alert>
    );
}

export default NotAllReadingsForSegmentAlert;
