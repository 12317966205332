import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useData } from '../../contexts/data.context';
import './BasalAnalyse.scss';
import BasalDateAnalyse from './BasalDateAnalyse/BasalDateAnalyse';
import BasalSegmentAnalyse from './BasalSegmentAnalyse/BasalSegmentAnalyse';

function BasalAnalyse() {
    const [grouping, setGrouping] = useState('byDay');

    const { url } = useData();
    const navigate = useNavigate();

    useEffect(() => {
        if (!url || url === '') {
            navigate('/settings');
        }
    }, []);

    if (!url || url === '') {
        return <></>;
    }

    return (
        <div className="analyse-page">
            <div className="group-selection">
                <FormControl component="fieldset">
                    <FormLabel component="legend">Gruppierung</FormLabel>
                    <RadioGroup
                        row
                        aria-label="gender"
                        name="row-radio-buttons-group"
                        value={grouping}
                        onChange={(event) => setGrouping(event.target.value)}
                    >
                        <FormControlLabel
                            value="byDay"
                            control={<Radio />}
                            label="Nach Tagen"
                        />
                        <FormControlLabel
                            value="bySegment"
                            control={<Radio />}
                            label="Nach Segmenten"
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            {grouping === 'byDay' && <BasalDateAnalyse />}
            {grouping === 'bySegment' && <BasalSegmentAnalyse />}
        </div>
    );
}

export default BasalAnalyse;
