import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useData } from '../../contexts/data.context';
import { useError } from '../../contexts/error.context';
import { NightscoutStatus } from '../../models/enums/nightscout-status';
import NightscoutService from '../../services/nightscout.service';
import ApiTokenError from './ApiTokenError/ApiTokenError';
import NetworkError from './NetworkError/NetworkError';
import './Settings.scss';

function Settngs() {
    const {
        url,
        setUrl,
        apiSecret,
        setApiSecret,
        segmentCount,
        setSegmentCount,
        insulinDuration,
        setInsulinDuration,
    } = useData();
    const [newUrl, setNewUrl] = useState('');
    const [newApiSecret, setNewApiSecret] = useState('');
    const [newSegmentCount, setNewSegmentCount] = useState(24);
    const [newInsulinDuration, setNewInsulinDuration] = useState('05:00');
    const [displayTokenError, setDisplayTokenError] = useState(false);
    const [displayNetworkError, setDisplayNetworkError] = useState(false);
    const [, setError] = useError();
    const navigate = useNavigate();

    useEffect(() => {
        setNewUrl(url ?? '');
        setNewApiSecret(apiSecret ?? '');
        setNewSegmentCount(segmentCount);
        setNewInsulinDuration(insulinDuration);
    }, []);

    const start = async (event: any) => {
        event.preventDefault();
        if (!newUrl || newUrl === '') {
            setError('Bitte gib deine Nightscout Url an.');
            return;
        }

        try {
            const nightscoutService = new NightscoutService(
                newUrl,
                newApiSecret
            );
            const result = await nightscoutService.getStatus();

            if (result === NightscoutStatus.Ok) {
                setUrl(newUrl);
                setApiSecret(newApiSecret);
                setSegmentCount(newSegmentCount);
                setInsulinDuration(newInsulinDuration);
                window.localStorage.setItem('nightscoutUrl', newUrl ?? '');
                window.localStorage.setItem('apiToken', newApiSecret ?? '');
                window.localStorage.setItem(
                    'insulinDuration',
                    newInsulinDuration
                );
                window.localStorage.setItem(
                    'segmentCount',
                    newSegmentCount.toString() ?? ''
                );
                navigate('/');
                return;
            } else if (result === NightscoutStatus.WrongApiToken) {
                setDisplayTokenError(true);
                return;
            }
            setDisplayNetworkError(true);
        } catch (err) {}
    };

    return (
        <div className="start-page">
            <div className="input-container">
                <h1>Einstellungen</h1>
                <TextField
                    fullWidth
                    value={newUrl}
                    onChange={(event) => setNewUrl(event.target.value)}
                    id="NichtscoutUrl"
                    label="Nightscout Url"
                    margin="normal"
                    helperText="Beispiel: https://user.herokuapp.com/ oder https://user.ns.10be.de:14881/"
                />

                <TextField
                    fullWidth
                    value={newApiSecret}
                    onChange={(event) => setNewApiSecret(event.target.value)}
                    id="NichtScoutApiSecret"
                    label="API Token"
                    margin="normal"
                    helperText="Das API Token kannst du in Nightscout unter 'Administrator-Werkzeuge' -> 'Subjekte' mit der Rolle 'readable' hinzufügen."
                />

                <FormControl margin="normal" className="segment-select">
                    <InputLabel id="demo-simple-select-label">
                        Anzahl der Segmente
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={newSegmentCount}
                        label="Anzahl der Segmente"
                        onChange={(event: any) =>
                            setNewSegmentCount(parseInt(event.target.value, 10))
                        }
                    >
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                    </Select>
                </FormControl>
                <FormHelperText>
                    Deine Glukosedaten werden zur Analyse in die hier
                    ausgewählte Anzahl an Segmenten unterteilt.
                </FormHelperText>
                <TextField
                    id="time"
                    label="Insulinwirkdauer"
                    type="time"
                    margin="normal"
                    value={newInsulinDuration}
                    onChange={(event) =>
                        setNewInsulinDuration(event.target.value)
                    }
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 60, // 5 min
                    }}
                    sx={{ width: 150 }}
                />

                <div className="button-container">
                    <Button variant="contained" onClick={start}>
                        Speichern
                    </Button>
                </div>
            </div>
            <Modal
                open={displayTokenError}
                onClose={() => setDisplayTokenError(false)}
            >
                <Box>
                    <ApiTokenError close={() => setDisplayTokenError(false)} />
                </Box>
            </Modal>
            <Modal
                open={displayNetworkError}
                onClose={() => setDisplayNetworkError(false)}
            >
                <Box>
                    <NetworkError close={() => setDisplayNetworkError(false)} />
                </Box>
            </Modal>
        </div>
    );
}

export default Settngs;
