import ReadingModel from '../models/dtos/reading.model';

export const getReadingsInTimeRange = (
    allReadings: ReadingModel[],
    rangeStart: Date,
    rangeEnd: Date
) => {
    return allReadings.filter(
        (reading) =>
            reading.localDate >= rangeStart && reading.localDate <= rangeEnd
    );
};
