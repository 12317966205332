import { Alert, Snackbar } from '@mui/material';
import React, { FunctionComponent, useContext, useState } from 'react';

// eslint-disable-next-line no-undef
export const SuccessContext = React.createContext<
    [
        string | undefined,
        React.Dispatch<React.SetStateAction<string | undefined>>
    ]
>({} as any);

export const SuccessProvider: FunctionComponent = (props) => {
    const successMessageState = useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = successMessageState;
    return (
        <SuccessContext.Provider value={successMessageState}>
            {props.children}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={!!successMessage}
                autoHideDuration={5000}
                message={successMessage}
                onClose={() => setSuccessMessage(undefined)}
            >
                <Alert severity="success" elevation={6} variant="filled">
                    {successMessage}
                </Alert>
            </Snackbar>
        </SuccessContext.Provider>
    );
};

export const useSuccess = () => {
    const context = useContext(SuccessContext);

    if (!context) throw new Error('useSuccess must be inside a ErrorProvider');

    return context;
};
