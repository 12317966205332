import BolusModel from '../models/dtos/treatment.model';
import moment from 'moment';

export const getTreatmentEnd = (
    treatment: BolusModel,
    insulinDurationText: string
) => {
    const insulinDurationInMinutes =
        getInsulinDurationInMinutes(insulinDurationText);
    let duration = insulinDurationInMinutes;
    if (treatment.duration) {
        duration += treatment.duration;
    }
    const treatmentEnd = moment(treatment.localDate)
        .add('minutes', duration)
        .toDate();

    return treatmentEnd;
};

export const areTreatmentsOverlapping = (
    a: BolusModel,
    b: BolusModel,
    insulinDuration: string
) => {
    if ((b as any)._id === '61bfa41a5ceee7f4717a1f4e') {
        debugger;
    }
    if (a === b) return false;

    // if treatment to check has insulin
    if (b.insulin) {
        const treatementAEnd = getTreatmentEnd(a, insulinDuration);
        const treatementBEnd = getTreatmentEnd(b, insulinDuration);
        if (b.localDate > treatementAEnd || treatementBEnd < a.localDate) {
            return false;
        }

        return true;
    }

    if (b.carbs) {
        const treatementAEnd = getTreatmentEnd(a, insulinDuration);
        return b.localDate >= a.localDate && b.localDate <= treatementAEnd;
    }

    return false;
};

export const getInsulinDurationInMinutes = (
    insulinDurationText: string
): number => {
    const split = insulinDurationText.split(':');
    return parseInt(split[0], 10) * 60 + parseInt(split[1], 10);
};
