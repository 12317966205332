import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { DatePicker } from '@mui/lab';
import { IconButton, TextField } from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';
import './AnalyseDatePicker.scss';

interface AnalyseDatePickerProps {
    dateChanged: (newDate: Date) => void;
}

function AnalyseDatePicker(props: AnalyseDatePickerProps): JSX.Element {
    const today = moment(new Date()).startOf('day').toDate();
    const [currentDay, setCurrentDay] = React.useState(today);

    useEffect(() => {
        dateChanged(currentDay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDay]);

    const dateChanged = (date: Date) => {
        props.dateChanged(date);
    };

    const nextDay = () => {
        if (!isToday()) {
            var currentDayMoment = moment(currentDay);
            currentDayMoment.add('days', 1);
            setCurrentDay(currentDayMoment.toDate());
        }
    };

    const prevDay = () => {
        var currentDayMoment = moment(currentDay);
        currentDayMoment.subtract('days', 1);
        setCurrentDay(currentDayMoment.toDate());
    };

    const isToday = () => {
        return moment(currentDay).isSame(today, 'day');
    };

    return (
        <div className="date-picker">
            <IconButton onClick={prevDay} className="date-change-button prev">
                <ArrowBackRoundedIcon />
            </IconButton>
            <DatePicker
                mask="__.__.____"
                maxDate={moment(new Date())}
                value={currentDay}
                onChange={(newValue) => {
                    if (!newValue) return;
                    setCurrentDay(newValue.toDate() ?? today);
                }}
                renderInput={(params) => <TextField {...params} />}
            />
            <IconButton
                onClick={nextDay}
                disabled={isToday()}
                className="date-change-button next"
            >
                <ArrowForwardRoundedIcon />
            </IconButton>
        </div>
    );
}

export default AnalyseDatePicker;
