import 'chartjs-adapter-moment';
import moment from 'moment';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { useData } from '../../contexts/data.context';
import { getTreatmentEnd } from '../../helper/insulin-duration.helper';
import AnalyseChartModel from '../../models/analyseChart.model';
import './AnalyseChart.scss';

interface AnalyseChartProps {
    chartModel: AnalyseChartModel;
    displayXLabels?: boolean;
    startHighlight?: Date;
    endHighlight?: Date;
    hideLines?: boolean;
}

function AnalyseChart(props: AnalyseChartProps): JSX.Element {
    const { insulinDuration } = useData();
    const id = `chart${props.chartModel.start.getTime()}`;

    const annotations: any[] = [];

    if (!!props.startHighlight && !!props.endHighlight) {
        annotations.push({
            type: 'box',
            xScaleID: 'xAxis',
            yScaleID: 'yAxis',
            borderWidth: 0,
            drawTime: 'beforeDatasetsDraw',
            xMin: props.startHighlight.getTime(),
            xMax: props.endHighlight.getTime(),
            yMin: props.chartModel.minValue,
            yMax: props.chartModel.maxValue,
            backgroundColor: 'rgba(150, 150, 150, 0.1)',
        });
    }

    props.chartModel.treatments
        .filter((x) => x.insulin)
        .forEach((treatment) => {
            let start = treatment.localDate;
            if (start < props.chartModel.start) start = props.chartModel.start;

            let end = getTreatmentEnd(treatment, insulinDuration);
            if (end > props.chartModel.end) end = props.chartModel.end;

            let label: any;
            if (start === treatment.localDate) {
                label = {
                    content: treatment.insulin + ' IE',
                    enabled: true,
                    position: '0%',
                    textAlign: 'center',
                    color: '#555555',
                    font: {
                        size: 16,
                        family: 'Quicksand',
                    },
                };
            }

            annotations.push({
                type: 'box',
                xScaleID: 'xAxis',
                yScaleID: 'yAxis',
                borderWidth: 0,
                drawTime: 'beforeDatasetsDraw',
                xMin: start.getTime(),
                xMax: end.getTime(),
                yMin: props.chartModel.minValue,
                yMax:
                    props.chartModel.minValue +
                    (props.chartModel.maxValue - props.chartModel.minValue) / 4,
                backgroundColor: 'rgba(157, 223, 243, 0.5)',
                label,
            });
        });
    return (
        <Line
            datasetIdKey={id}
            data={{
                datasets: [
                    {
                        label: '',
                        data: props.chartModel.usableReadings.map((r) => {
                            return {
                                x: r.localDate.getTime(),
                                y: r.glucoseValue,
                            };
                        }),
                        tension: 0.5,
                        showLine: !props.hideLines,
                    },
                    {
                        label: '',
                        data: props.chartModel.foods.map((r) => {
                            return {
                                x: r.localDate.getTime(),
                                y:
                                    props.chartModel.maxValue -
                                    (props.chartModel.maxValue -
                                        props.chartModel.minValue) /
                                        9,
                            };
                        }),
                        tension: 0.5,
                        pointStyle: 'rectRounded',
                        pointRadius: 10,
                        pointHoverRadius: 15,
                        pointBackgroundColor: '#00b81f97',
                        showLine: false,
                    },
                ],
            }}
            options={{
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            title: (context: any) => {
                                if (context[0].datasetIndex === 0) {
                                    return context[0].formattedValue + ' mg/dl';
                                }

                                return (
                                    props.chartModel.foods[context[0].dataIndex]
                                        .carbs + ' gr. KH'
                                );
                            },
                            label: (context: any) => {
                                if (context.datasetIndex === 0) {
                                    return moment(
                                        props.chartModel.usableReadings[
                                            context.dataIndex
                                        ].localDate
                                    ).format('HH:mm');
                                }

                                return moment(
                                    props.chartModel.foods[context.dataIndex]
                                        .localDate
                                ).format('HH:mm');
                            },
                        },
                    },
                    annotation: {
                        annotations: annotations as any,
                    },
                },
                elements: {
                    point: {
                        radius: 3,
                        pointStyle: 'circle',
                        backgroundColor: '#1976d2',
                    },
                },
                scales: {
                    xAxis: {
                        display: !!props.displayXLabels,
                        min: props.chartModel.start.getTime(),
                        max: props.chartModel.end.getTime(),
                        type: 'time',
                        time: {
                            unit: 'hour',
                        },
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, values) {
                                return `${index}:00`;
                            },
                        },
                    },
                    yAxis: {
                        min: props.chartModel.minValue,
                        max: props.chartModel.maxValue,
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, values) {
                                if (!((value as number) % 50 === 0))
                                    return null;
                                return value;
                            },
                        },
                    },
                },
            }}
        />
    );
}

export default AnalyseChart;
