import moment from 'moment';
import SegmentRangeModel from '../models/segment-range.model';

export const getSegmentTimeOfDay = (day: Date, segment: SegmentRangeModel) => {
    const fromSegmentSplit = segment?.from.split(':') ?? [];
    const toSegmentSplit = segment?.to.split(':') ?? [];

    const start = moment(day);
    start.set('hour', parseInt(fromSegmentSplit[0], 10));
    start.set('minute', parseInt(fromSegmentSplit[1], 10));
    start.set('second', 0);

    const end = moment(day);
    if (segment?.to === '00:00') {
        // 0 oclock on next day
        end.set('hour', 23);
        end.set('minute', 59);
        end.set('second', 59);
        end.set('millisecond', 999);
    } else {
        end.set('hour', parseInt(toSegmentSplit[0], 10));
        end.set('minute', parseInt(toSegmentSplit[1], 10));
        end.set('second', 0);
    }

    return { start, end };
};
