import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';
import { useData } from '../../contexts/data.context';
import SegmentRangeModel from '../../models/segment-range.model';
import './SegmentPicker.scss';

interface SegmentPickerProps {
    segmentChanged: (newSegment: SegmentRangeModel) => void;
}

interface SegmentCollection {
    16: SegmentRangeModel[];
    24: SegmentRangeModel[];
}

const getSegments = (segmentCount: number) => {
    if (segmentCount === 16) {
        return [
            { from: '00:00', to: '01:30' },
            { from: '01:30', to: '03:00' },
            { from: '03:00', to: '04:30' },
            { from: '04:30', to: '06:00' },
            { from: '06:00', to: '07:30' },
            { from: '07:30', to: '09:00' },
            { from: '09:00', to: '10:30' },
            { from: '10:30', to: '12:00' },
            { from: '12:00', to: '13:30' },
            { from: '13:30', to: '15:00' },
            { from: '15:00', to: '16:30' },
            { from: '16:30', to: '18:00' },
            { from: '18:00', to: '19:30' },
            { from: '19:30', to: '21:00' },
            { from: '21:00', to: '22:30' },
            { from: '22:30', to: '00:00' },
        ];
    }

    return [
        { from: '00:00', to: '01:00' },
        { from: '01:00', to: '02:00' },
        { from: '02:00', to: '03:00' },
        { from: '03:00', to: '04:00' },
        { from: '04:00', to: '05:00' },
        { from: '05:00', to: '06:00' },
        { from: '06:00', to: '07:00' },
        { from: '07:00', to: '08:00' },
        { from: '08:00', to: '09:00' },
        { from: '09:00', to: '10:00' },
        { from: '10:00', to: '11:00' },
        { from: '11:00', to: '12:00' },
        { from: '12:00', to: '13:00' },
        { from: '13:00', to: '14:00' },
        { from: '14:00', to: '15:00' },
        { from: '15:00', to: '16:00' },
        { from: '16:00', to: '17:00' },
        { from: '17:00', to: '18:00' },
        { from: '18:00', to: '19:00' },
        { from: '19:00', to: '20:00' },
        { from: '20:00', to: '21:00' },
        { from: '21:00', to: '22:00' },
        { from: '22:00', to: '23:00' },
        { from: '23:00', to: '00:00' },
    ];
};

function SegmentPicker(props: SegmentPickerProps): JSX.Element {
    const { segmentCount } = useData();
    const [currentSegmentIndex, setCurrentSegmentIndex] = React.useState(0);

    useEffect(() => {
        props.segmentChanged(getSegments(segmentCount)[0]);
    }, []);

    const segmentChanged = (index: number) => {
        setCurrentSegmentIndex(index);
        props.segmentChanged(getSegments(segmentCount)[index]);
    };

    return (
        <div className="segment-picker">
            <FormControl>
                <InputLabel id="demo-simple-select-label">Segment</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={currentSegmentIndex}
                    label="Segment"
                    onChange={(event) =>
                        segmentChanged(event.target.value as number)
                    }
                >
                    {getSegments(segmentCount).map((segment, index) => (
                        <MenuItem value={index} key={segment.from}>
                            {segment.from} - {segment.to}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default SegmentPicker;
