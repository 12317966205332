import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Box, IconButton, Modal, Paper } from '@mui/material';
import 'chartjs-adapter-moment';
import moment from 'moment';
import React, { useState } from 'react';
import AnalyseChart from '../../../components/AnalyseChart/AnalyseChart';
import DayView from '../../../components/DayView/DayView';
import NotAllReadingsForSegmentAlert from '../../../components/NotAllReadingsForSegmentAlert/NotAllReadingsForSegmentAlert';
import OverlappingTreatmentAlert from '../../../components/OverlappingTreatmentAlert/OverlappingTreatmentAlert';
import BolusSegmentModel from '../../../models/bolus-segment.model';
import FoodModel from '../../../models/dtos/food.model';
import './BolusSegmentView.scss';

interface BolusSegmentViewProps {
    segment: BolusSegmentModel;
}

function BolusSegmentView(props: BolusSegmentViewProps): JSX.Element {
    const [displayDayView, setDisplayDayView] = useState(false);

    if (props.segment.readings.length === 0) {
        return <></>;
    }

    const getTrendArrow = (glucoseDiff: number) => {
        if (glucoseDiff >= -5 && glucoseDiff <= 5) {
            return <ArrowForwardRoundedIcon></ArrowForwardRoundedIcon>;
        }

        if (glucoseDiff >= 15) {
            return (
                <ArrowForwardRoundedIcon className="trendSpikeUp"></ArrowForwardRoundedIcon>
            );
        }

        if (glucoseDiff >= 5) {
            return (
                <ArrowForwardRoundedIcon className="trendUp"></ArrowForwardRoundedIcon>
            );
        }

        if (glucoseDiff <= -15) {
            return (
                <ArrowForwardRoundedIcon className="trendSpikeDown"></ArrowForwardRoundedIcon>
            );
        }

        if (glucoseDiff <= -5) {
            return (
                <ArrowForwardRoundedIcon className="trendDown"></ArrowForwardRoundedIcon>
            );
        }
    };

    const usableReadings = props.segment.readings.filter(
        (x: any) => x.glucoseValue > 20
    );

    let startGlucoseLevel;
    let endGlucoseLevel;
    let glucoseDiff = 0;
    try {
        if (usableReadings.length > 0) {
            const startReading = usableReadings[0];
            const endReading = usableReadings[usableReadings.length - 1];
            startGlucoseLevel = startReading.glucoseValue;
            endGlucoseLevel = endReading.glucoseValue;

            glucoseDiff = endGlucoseLevel - startGlucoseLevel;
        }
    } catch (err) {
        console.log(err);
    }

    const getGlucoseDiffText = (diff: number) => {
        if (diff >= 0) {
            return `+${diff} mg/dl`;
        }

        return `${diff} mg/dl`;
    };

    const foods: FoodModel[] = props.segment.overlappingTreatments
        .filter((x) => x.carbs)
        .map((x) => {
            return {
                localDate: x.localDate,
                carbs: x.carbs,
            };
        });

    const roundTo2Digits = (num: number) => {
        return Math.round(num * 100) / 100;
    };

    const showDayView = () => {
        setDisplayDayView(true);
    };

    const notAllReadingsForSegment =
        moment(props.segment.end).diff(
            moment(usableReadings[usableReadings.length - 1].localDate),
            'minutes'
        ) > 6;

    return (
        <>
            <Paper elevation={3} className="segment-view" onClick={showDayView}>
                <h2>
                    {moment(props.segment.start).format('HH:mm')} -{' '}
                    {moment(props.segment.end).format('HH:mm')}
                </h2>
                <div className="date">
                    {moment(props.segment.start).format('dd DD.MM.yyyy')}
                </div>
                <div className="content">
                    <div className="chart-container">
                        <AnalyseChart
                            hideLines={true}
                            chartModel={{
                                end: props.segment.end,
                                foods: foods,
                                maxValue: props.segment.maxValue,
                                minValue: props.segment.minValue,
                                start: props.segment.start,
                                treatments: [
                                    props.segment.treatment,
                                    ...props.segment.overlappingTreatments,
                                ],
                                usableReadings,
                            }}
                        ></AnalyseChart>
                        <IconButton className="date-view-button">
                            <SearchRoundedIcon />
                        </IconButton>
                    </div>
                    <div className="details-container">
                        {usableReadings.length > 0 && (
                            <div className="details">
                                <div className="carbs">
                                    {props.segment.treatment.carbs} gr. KH -{' '}
                                    {props.segment.treatment.insulin} IE
                                </div>
                                <div className="trend">
                                    {startGlucoseLevel} mg/dl
                                    <ArrowRightAltRoundedIcon />
                                    {endGlucoseLevel} mg/dl
                                </div>
                                <div className="diff">
                                    {getTrendArrow(glucoseDiff)}
                                    {getGlucoseDiffText(glucoseDiff)}
                                </div>
                                <div className="bolus-factor-container">
                                    <div className="bolus-factor">
                                        &#8792; KH-Verhältnis:{' '}
                                        {roundTo2Digits(
                                            props.segment.treatment.carbs /
                                                props.segment.treatment.insulin
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {props.segment.overlappingTreatments.length > 0 && (
                    <OverlappingTreatmentAlert />
                )}
                {notAllReadingsForSegment && <NotAllReadingsForSegmentAlert />}
            </Paper>
            <Modal
                open={displayDayView}
                onClose={() => setDisplayDayView(false)}
            >
                <Box>
                    <DayView
                        date={props.segment.start}
                        startHighlight={props.segment.start}
                        endHighlight={props.segment.end}
                    />
                </Box>
            </Modal>
        </>
    );
}

export default BolusSegmentView;
