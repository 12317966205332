import { TextField } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import SegmentPicker from '../../../components/SegmentPicker/SegmentPicker';
import { useData } from '../../../contexts/data.context';
import ReadingModel from '../../../models/dtos/reading.model';
import BolusModel from '../../../models/dtos/treatment.model';
import SegmentRangeModel from '../../../models/segment-range.model';
import NightscoutService from '../../../services/nightscout.service';
import BasalCurrentSegmentView from './../BasalCurrentSegmentView/BasalCurrentSegmentView';
import './BasalSegmentAnalyse.scss';

function BasalSegmentAnalyse() {
    const [readings, setReadings] = useState<ReadingModel[]>([]);
    const [treatments, setTreatments] = useState<BolusModel[]>([]);
    const [segment, setSegment] = useState<SegmentRangeModel | undefined>(
        undefined
    );
    const [dayCount, setDayCount] = useState(30);
    const [isLoading, setIsLoading] = useState(false);
    const { apiSecret, url } = useData();
    const navigate = useNavigate();

    useEffect(() => {
        if (!url || url === '') {
            navigate('/settings');
            return;
        }
        loadSegmentValues();
    }, []);

    const loadSegmentValues = async () => {
        setIsLoading(true);
        const nightscoutService = new NightscoutService(url, apiSecret);
        const readingDtos = await nightscoutService.getReadingsOfLastDays(
            dayCount
        );
        const treatmentDtos = await nightscoutService.getTreatmentsForLastDays(
            dayCount
        );

        let readingModels: ReadingModel[] = readingDtos.map((reading) => {
            return {
                localDate: moment(reading.dateString).local().toDate(),
                glucoseValue: reading.sgv,
            };
        });

        let bolusModels: BolusModel[] = treatmentDtos.map((treatment) => {
            return {
                localDate: moment(treatment.created_at).local().toDate(),
                insulin: treatment.insulin,
                duration: treatment.duration,
                carbs: treatment.carbs,
            };
        });

        readingModels = readingModels.sort((a, b) => {
            return a.localDate.getTime() - b.localDate.getTime();
        });
        bolusModels = bolusModels.sort((a, b) => {
            return a.localDate.getTime() - b.localDate.getTime();
        });

        setReadings(readingModels);
        setTreatments(bolusModels);
        setIsLoading(false);
    };

    useEffect(() => {
        loadSegmentValues();
    }, [segment, dayCount]);

    const segmentChanged = async (segment: SegmentRangeModel) => {
        setSegment(segment);
    };

    const dayCountChanged = async (days: number) => {
        setDayCount(days);
    };

    if (!url || url === '') {
        return <></>;
    }

    return (
        <>
            <div className="filter-container">
                <SegmentPicker segmentChanged={segmentChanged}></SegmentPicker>
                <TextField
                    id="outlined-number"
                    label="Daten der letzten x Tage"
                    type="number"
                    value={dayCount}
                    onChange={(event) =>
                        dayCountChanged(parseInt(event.target.value, 10))
                    }
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            {!isLoading && !!segment && (
                <BasalCurrentSegmentView
                    readings={readings}
                    treatments={treatments}
                    segment={segment}
                    dayCount={dayCount}
                ></BasalCurrentSegmentView>
            )}
            {isLoading && <LoadingSpinner />}
        </>
    );
}

export default BasalSegmentAnalyse;
