import moment from 'moment';
import React from 'react';
import { useData } from '../../../contexts/data.context';
import {
    areTreatmentsOverlapping,
    getTreatmentEnd,
} from '../../../helper/insulin-duration.helper';
import BolusSegmentModel from '../../../models/bolus-segment.model';
import ReadingModel from '../../../models/dtos/reading.model';
import BolusModel from '../../../models/dtos/treatment.model';
import BolusSegmentView from '../BolusSegmentView/BolusSegmentView';
import './BolusCurrentDateView.scss';

interface BolusCurrentDateViewProps {
    readings: ReadingModel[];
    treatments: BolusModel[];
    date: Date | undefined;
}

function BolusCurrentDateView(props: BolusCurrentDateViewProps): JSX.Element {
    const { insulinDuration } = useData();

    const getTimeSegments = () => {
        if (props.readings.length === 0) {
            return [];
        }

        const segments: BolusSegmentModel[] = [];

        const sortedByGlucoseLevel = [...props.readings];
        sortedByGlucoseLevel.sort((a, b) => a.glucoseValue - b.glucoseValue);

        const mealtreatments = props.treatments.filter(
            (x) =>
                !!x.insulin &&
                !!x.carbs &&
                moment(x.localDate).isSame(moment(props.date), 'day')
        );

        mealtreatments.forEach((treatment) => {
            const startOfSegment = treatment.localDate;
            const endOfSegment = getTreatmentEnd(treatment, insulinDuration);

            const readingsOfSegment = props.readings.filter(
                (x) =>
                    x.localDate >= startOfSegment && x.localDate <= endOfSegment
            );

            const overlappingTreatments = props.treatments.filter((x) =>
                areTreatmentsOverlapping(treatment, x, insulinDuration)
            );

            let minGlucoseValue =
                sortedByGlucoseLevel.length > 0
                    ? sortedByGlucoseLevel[0].glucoseValue
                    : 30;

            const maxGlucoseValue =
                sortedByGlucoseLevel.length > 0
                    ? sortedByGlucoseLevel[sortedByGlucoseLevel.length - 1]
                          .glucoseValue + 15
                    : 200;

            const barHeight = (maxGlucoseValue - minGlucoseValue) / 4 + 10;
            minGlucoseValue -= barHeight;

            segments.push({
                start: startOfSegment,
                end: endOfSegment,
                readings: readingsOfSegment,
                treatment: treatment,
                overlappingTreatments: overlappingTreatments,
                minValue: minGlucoseValue,
                maxValue: maxGlucoseValue,
            });
        });

        return segments;
    };

    if (!props.date) {
        return <></>;
    }

    const segments = getTimeSegments();

    return (
        <div className="current-date-view">
            <h2>Mahlzeiten-Bolus Analyse</h2>
            <p>
                Hier werden alle deine Mahlzeiten-Boli aufgelistet um die
                Auswirkung von Insulin und Kohlenhydraten zum Ende des aktiven
                Insulins darzustellen.
            </p>
            <ul>
                {segments.map((segment) => (
                    <li key={segment.start.toString()}>
                        <BolusSegmentView segment={segment} />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default BolusCurrentDateView;
