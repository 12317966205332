import React from 'react';
import { useData } from '../../../contexts/data.context';
import { getReadingsInTimeRange } from '../../../helper/filter.helper';
import { getTreatmentEnd } from '../../../helper/insulin-duration.helper';
import { getSegmentTimeOfDay } from '../../../helper/segment-time-helper';
import ReadingModel from '../../../models/dtos/reading.model';
import BolusModel from '../../../models/dtos/treatment.model';
import SegmentRangeModel from '../../../models/segment-range.model';
import SegmentModel from '../../../models/segment.model';
import BasalSegmentView from '../BasalSegmentView/BasalSegmentView';
import './BasalCurrentSegmentView.scss';

interface BasalCurrentSegmentViewProps {
    readings: ReadingModel[];
    treatments: BolusModel[];
    segment: SegmentRangeModel;
    dayCount: number;
}

function BasalCurrentSegmentView(
    props: BasalCurrentSegmentViewProps
): JSX.Element {
    const { insulinDuration } = useData();

    const getTimeSegments = () => {
        if (props.readings.length === 0) {
            return [];
        }

        const { start, end } = getSegmentTimeOfDay(new Date(), props.segment);

        const segments: SegmentModel[] = [];

        const sortedByGlucoseLevel = [...props.readings];
        sortedByGlucoseLevel.sort((a, b) => a.glucoseValue - b.glucoseValue);

        for (let i = 0; i < props.dayCount; i++) {
            const startDate = start.toDate();
            const endDate = end.toDate();

            const readingsOfSegment = getReadingsInTimeRange(
                props.readings,
                startDate,
                endDate
            );

            const treatmentsOfSegment = props.treatments.filter((treatment) => {
                const treatmentStart = treatment.localDate;
                const treatmentEnd = getTreatmentEnd(
                    treatment,
                    insulinDuration
                );

                return !(treatmentEnd < startDate || treatmentStart > endDate);
            });

            const foodsOfSegment = props.treatments.filter((treatment) => {
                return (
                    !!treatment.carbs &&
                    treatment.localDate >= startDate &&
                    treatment.localDate <= endDate
                );
            });

            let minGlucoseValue =
                sortedByGlucoseLevel.length > 0
                    ? sortedByGlucoseLevel[0].glucoseValue
                    : 30;

            const maxGlucoseValue =
                sortedByGlucoseLevel.length > 0
                    ? sortedByGlucoseLevel[sortedByGlucoseLevel.length - 1]
                          .glucoseValue + 15
                    : 200;

            const barHeight = (maxGlucoseValue - minGlucoseValue) / 4 + 10;
            minGlucoseValue -= barHeight;
            segments.push({
                start: startDate,
                end: endDate,
                readings: readingsOfSegment,
                treatments: treatmentsOfSegment,
                foods: foodsOfSegment,
                minValue: minGlucoseValue,
                maxValue: maxGlucoseValue,
            });

            start.subtract('days', 1);
            end.subtract('days', 1);
        }

        return segments;
    };

    const segments = getTimeSegments();

    return (
        <div className="current-date-view">
            <h2>Basalratenanalyse</h2>
            <ul>
                {segments.map((segment) => (
                    <li key={segment.start.toString()}>
                        <BasalSegmentView segment={segment} />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default BasalCurrentSegmentView;
