import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from '../../../components/AnalyseDatePicker/AnalyseDatePicker';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { useData } from '../../../contexts/data.context';
import FoodModel from '../../../models/dtos/food.model';
import ReadingModel from '../../../models/dtos/reading.model';
import BolusModel from '../../../models/dtos/treatment.model';
import NightscoutService from '../../../services/nightscout.service';
import BasalCurrentDateView from './../BasalCurrentDateView/BasalCurrentDateView';
import './BasalDateAnalyse.scss';

function BasalDateAnalyse() {
    const [readings, setReadings] = useState<ReadingModel[]>([]);
    const [treatments, setTreatments] = useState<BolusModel[]>([]);
    const [foods, setFoods] = useState<FoodModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { apiSecret, url } = useData();
    const navigate = useNavigate();

    useEffect(() => {
        if (!url || url === '') {
            navigate('/settings');
        }
    }, []);

    const loadDateValues = async (date: Date) => {
        setIsLoading(true);
        const nightscoutService = new NightscoutService(url, apiSecret);
        const readingDtos = await nightscoutService.getReadingsForDay(date, 1);
        const treatmentDtos = await nightscoutService.getTreatmentsForDay(date);

        let readingModels: ReadingModel[] = readingDtos.map((reading) => {
            return {
                localDate: moment(reading.dateString).local().toDate(),
                glucoseValue: reading.sgv,
            };
        });

        let bolusModels: BolusModel[] = treatmentDtos
            .filter((x) => !!x.insulin)
            .map((treatment) => {
                return {
                    localDate: moment(treatment.created_at).local().toDate(),
                    insulin: treatment.insulin,
                    duration: treatment.duration,
                    carbs: treatment.carbs,
                };
            });

        //TODO remove
        const foodModels: FoodModel[] = treatmentDtos
            .filter((x) => !!x.carbs)
            .map((x) => {
                return {
                    localDate: moment(x.created_at).local().toDate(),
                    carbs: x.carbs,
                };
            });

        readingModels = readingModels.sort((a, b) => {
            return a.localDate.getTime() - b.localDate.getTime();
        });
        bolusModels = bolusModels.sort((a, b) => {
            return a.localDate.getTime() - b.localDate.getTime();
        });

        setReadings(readingModels);
        setTreatments(bolusModels);
        setFoods(foodModels);
        setIsLoading(false);
    };

    const dateChanged = async (date: Date) => {
        await loadDateValues(date);
    };

    if (!url || url === '') {
        return <></>;
    }

    return (
        <>
            <DatePicker dateChanged={dateChanged}></DatePicker>
            {!isLoading && (
                <BasalCurrentDateView
                    readings={readings}
                    treatments={treatments}
                    foods={foods}
                ></BasalCurrentDateView>
            )}
            {isLoading && <LoadingSpinner />}
        </>
    );
}

export default BasalDateAnalyse;
