import React, { useContext, useState } from 'react';

export interface DataProps {
    url: string | undefined;
    setUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
    apiSecret: string | undefined;
    setApiSecret: React.Dispatch<React.SetStateAction<string | undefined>>;
    insulinDuration: string;
    setInsulinDuration: React.Dispatch<React.SetStateAction<string>>;
    segmentCount: number;
    setSegmentCount: React.Dispatch<React.SetStateAction<number>>;
}

export const DataContext = React.createContext<DataProps>({} as DataProps);

export const DataProvider = (props: any) => {
    const [url, setUrl] = useState<string | undefined>(
        window.localStorage.getItem('nightscoutUrl') ?? undefined
    );
    const [apiSecret, setApiSecret] = useState<string | undefined>(
        window.localStorage.getItem('apiToken') ?? undefined
    );
    const [insulinDuration, setInsulinDuration] = useState<string>(
        window.localStorage.getItem('insulinDuration') ?? '05:00'
    );
    const [segmentCount, setSegmentCount] = useState<number>(
        parseInt(window.localStorage.getItem('segmentCount') ?? '24', 10)
    );

    return (
        <DataContext.Provider
            value={{
                url,
                setUrl,
                apiSecret,
                setApiSecret,
                segmentCount,
                setSegmentCount,
                insulinDuration,
                setInsulinDuration,
            }}
        >
            {props.children}
        </DataContext.Provider>
    );
};

export const useData = () => {
    const context = useContext(DataContext);

    if (!context) throw new Error('useData must be inside a ClientProvider');

    return context;
};
