import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
    AppBar,
    createTheme,
    Divider,
    Drawer,
    IconButton,
    ThemeProvider,
    Toolbar,
    Typography,
} from '@mui/material';
import {
    ArcElement,
    BarController,
    BarElement,
    BubbleController,
    CategoryScale,
    Chart,
    DoughnutController,
    // Decimation,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    LogarithmicScale,
    PieController,
    PointElement,
    PolarAreaController,
    RadarController,
    RadialLinearScale,
    ScatterController,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import moment from 'moment';
import 'moment/locale/de';
import React, { useState } from 'react';
import { BrowserRouter, Link, Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import Navigation from './components/Navigation/Navigation';
import { DataProvider } from './contexts/data.context';
import { ErrorProvider } from './contexts/error.context';
import { SuccessProvider } from './contexts/success.context';
import BasalAnalyse from './pages/BasalAnalyse/BasalAnalyse';
import BolusAnalyse from './pages/BolusAnalyse/BolusAnalyse';
import CorrectionAnalyse from './pages/CorrectionAnalyse/CorrectionAnalyse';
import Impressum from './pages/Impressum/Impressum';
import Settngs from './pages/Settings/Settings';

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Filler,
    Legend,
    Title,
    Tooltip,
    annotationPlugin
);

moment.locale('de');

const theme = createTheme({
    palette: {
        primary: {
            main: '#2e75a1',
        },
    },
    // typography: {
    //     fontFamily: ['Quicksand', 'Roboto', 'sans-serif'].join(','),
    // },
});

function App() {
    const [displayMenu, setDisplayMenu] = useState(false);
    const toggleMenu = () => {
        setDisplayMenu((prev) => !prev);
    };

    return (
        <DataProvider>
            <SuccessProvider>
                <ErrorProvider>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                        <ThemeProvider theme={theme}>
                            <BrowserRouter>
                                <div className="app-content">
                                    <AppBar
                                        position="sticky"
                                        className="app-bar"
                                    >
                                        <Toolbar>
                                            <Typography
                                                variant="h6"
                                                component="div"
                                            >
                                                <Link to="/">
                                                    Nightscout Tools
                                                </Link>
                                            </Typography>
                                            <div className="top-menu">
                                                <Navigation />
                                            </div>

                                            <Link to="/settings">
                                                <IconButton
                                                    size="large"
                                                    aria-label="account of current user"
                                                    aria-controls="menu-appbar"
                                                    aria-haspopup="true"
                                                    color="inherit"
                                                >
                                                    <SettingsIcon />
                                                </IconButton>
                                            </Link>
                                            <IconButton
                                                size="large"
                                                aria-label="account of current user"
                                                aria-controls="menu-appbar"
                                                aria-haspopup="true"
                                                color="inherit"
                                                onClick={toggleMenu}
                                                className="menu-button"
                                            >
                                                <MenuIcon />
                                            </IconButton>
                                        </Toolbar>
                                        <Drawer
                                            open={displayMenu}
                                            onClose={() =>
                                                setDisplayMenu(false)
                                            }
                                            anchor="right"
                                        >
                                            <div className="drawer-menu">
                                                <div className="drawer-header">
                                                    <img
                                                        src="/carbsense_logo_dark.svg"
                                                        alt="logo"
                                                    />
                                                    Nightscout Tools
                                                </div>
                                                <Divider></Divider>
                                                <Navigation
                                                    linkClicked={() =>
                                                        setDisplayMenu(false)
                                                    }
                                                />
                                            </div>
                                        </Drawer>
                                    </AppBar>

                                    <Routes>
                                        <Route
                                            path="/settings"
                                            element={<Settngs />}
                                        ></Route>
                                        <Route
                                            path="/impressum"
                                            element={<Impressum />}
                                        ></Route>
                                        <Route
                                            path="/basalanalyse"
                                            element={<BasalAnalyse />}
                                        ></Route>
                                        <Route
                                            path="/bolusanalyse"
                                            element={<BolusAnalyse />}
                                        ></Route>
                                        <Route
                                            path="/correctionanalyse"
                                            element={<CorrectionAnalyse />}
                                        ></Route>
                                        <Route
                                            path="/"
                                            element={
                                                <Navigate
                                                    replace
                                                    to="/basalanalyse"
                                                />
                                            }
                                        />
                                    </Routes>
                                </div>
                                <footer>
                                    <Link to="/impressum">Impressum</Link>
                                    <a
                                        href="https://www.carbsense.de"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="carbsense-logo"
                                    >
                                        by
                                        <img
                                            src="/carbsense_logo.svg"
                                            alt="CarbSense Logo"
                                        ></img>
                                        CarbSense
                                    </a>
                                </footer>
                            </BrowserRouter>
                        </ThemeProvider>
                    </LocalizationProvider>
                </ErrorProvider>
            </SuccessProvider>
        </DataProvider>
    );
}

export default App;
