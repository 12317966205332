import { Button, Paper } from '@mui/material';
import React from 'react';
import './ApiTokenError.scss';

interface ApiTokenErrorProps {
    close: () => void;
}

function ApiTokenError(props: ApiTokenErrorProps) {
    return (
        <Paper elevation={3} className="error-container">
            <h3>Ungültiges API Token</h3>
            <p>
                So wie es aussieht, hast du deine Nightscout Instanz mit
                AUTH_DEFAULT_ROLES = "denied" gesichert.
            </p>
            <p>
                Damit der Zugriff auf Nightscout klappt, musst du folgende
                Einstellungen in Nightscout vornehmen:
            </p>
            <div className="image-paper">
                <img
                    src="/errors/wrong_api_token_1.png"
                    alt="Öffne das Menü"
                ></img>
                <p>Öffne das Menü</p>
            </div>
            <div className="image-paper">
                <img
                    src="/errors/wrong_api_token_2.png"
                    alt="Administrator-Werkzeuge"
                ></img>
                <p>Öffne die Administrator-Werkzeuge</p>
            </div>
            <div className="image-paper">
                <img
                    src="/errors/wrong_api_token_3.png"
                    alt="Subjekt hinzufügen"
                ></img>
                <p>Füge ein neues Subjekt hinzu</p>
            </div>
            <p>
                Den Namen des Subjekts kannst du frei wählen. Die Rolle sollte
                mindestens "readable" sein. Nachdem du ein neues Subjekt
                hinzugefügt hast, kannst den zugehörigen Zugriffsschlüssel hier
                als API Token verwenden.
            </p>
            <div className="button-container">
                <Button onClick={props.close}>Schließen</Button>
            </div>
        </Paper>
    );
}

export default ApiTokenError;
