import { Button, Paper } from '@mui/material';
import React from 'react';
import './NetworkError.scss';

interface NetworkErrorProps {
    close: () => void;
}

function NetworkError(props: NetworkErrorProps) {
    return (
        <Paper elevation={3} className="error-container">
            <h3>Fehler bei der Verbindung zu Nightscout</h3>
            <p>
                Wir können keine Verbindung zu deiner Nightscout Instanz
                herstellen. Das könnte folgende Ursachen haben:
            </p>
            <h4>Falsche Nigthscout Url</h4>
            <p>
                Bitte überprüfe deine Nightscout Url. Die Url sollte{' '}
                <b>nicht</b> mit /api/v1 enden und <b>kein</b> API-Token
                enthalten.
            </p>
            <h4>CORS Fehler</h4>
            <p>
                Füge in den Nightscout Variablen unter ENABLE "cors" hinzu. Das
                wird benötigt, damit deine Glukosedaten abgefragt werden können.
            </p>
            <div className="button-container">
                <Button onClick={props.close}>Schließen</Button>
            </div>
        </Paper>
    );
}

export default NetworkError;
