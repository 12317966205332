import axios from 'axios';
import moment from 'moment';
import ReadingDto from '../models/dtos/reading.dto';
import { NightscoutStatus } from '../models/enums/nightscout-status';
import TreatmentDto from '../models/dtos/treatment.dto';

export default class NightscoutService {
    constructor(
        private baseUrl: string | undefined,
        private apiSecret: string | undefined
    ) {}

    public async getStatus(): Promise<NightscoutStatus> {
        try {
            let url = 'status';
            if (this.apiSecret) {
                url += `?token=${this.apiSecret}`;
            }
            const result = await this.getAxiosClient(this.baseUrl).get(url);
            console.log('status', result);
            return NightscoutStatus.Ok;
        } catch (err: any) {
            const response = err.toJSON();
            if (response.status === 401) {
                return NightscoutStatus.WrongApiToken;
            }
            return NightscoutStatus.NetworkError;
        }
    }

    public async getReadingsForDay(
        date: Date,
        dayCount: number
    ): Promise<ReadingDto[]> {
        const startOfDate = moment(date).startOf('day');
        const from = moment.utc(startOfDate).toISOString();
        const nextDay = moment(date);
        nextDay.add('days', dayCount);
        const to = moment.utc(nextDay).toISOString();
        let searchUrl = `entries.json?find[dateString][$gte]=${from}&find[dateString][$lt]=${to}&count=10000`;
        if (this.apiSecret) {
            searchUrl += `&token=${this.apiSecret}`;
        }

        const result = await this.getAxiosClient(this.baseUrl).get(searchUrl);
        return result.data;
    }

    public async getReadingsOfLastDays(days: number): Promise<ReadingDto[]> {
        const now = moment(Date.now());
        const start = moment(Date.now()).startOf('day');
        start.subtract(days, 'days');

        console.log('start', start);

        const from = moment.utc(start).toISOString();
        const to = moment.utc(now).toISOString();

        let searchUrl = `entries.json?find[dateString][$gte]=${from}&find[dateString][$lt]=${to}&count=10000`;
        if (this.apiSecret) {
            searchUrl += `&token=${this.apiSecret}`;
        }

        const result = await this.getAxiosClient(this.baseUrl).get(searchUrl);
        return result.data;
    }

    public async getTreatmentsForDay(date: Date): Promise<TreatmentDto[]> {
        const nextDay = moment(date);
        nextDay.add('days', 1);
        const prevDay = moment(date);
        prevDay.subtract('days', 1);
        const from = moment.utc(prevDay).toISOString();
        const to = moment.utc(nextDay).toISOString();
        let searchUrl = `treatments.json?find[created_at][$gte]=${from}&find[created_at][$lt]=${to}&count=10000`;
        if (this.apiSecret) {
            searchUrl += `&token=${this.apiSecret}`;
        }

        const allTreatments = (
            await this.getAxiosClient(this.baseUrl).get(searchUrl)
        ).data;

        return allTreatments;
    }

    public async getTreatmentsForLastDays(
        dayCount: number
    ): Promise<TreatmentDto[]> {
        const now = moment(Date.now());
        const start = moment(Date.now()).startOf('day');
        start.subtract(dayCount, 'days');

        const from = moment.utc(start).toISOString();
        const to = moment.utc(now).toISOString();

        let searchUrl = `treatments.json?find[created_at][$gte]=${from}&find[created_at][$lt]=${to}&count=10000`;
        if (this.apiSecret) {
            searchUrl += `&token=${this.apiSecret}`;
        }

        const allTreatments = (
            await this.getAxiosClient(this.baseUrl).get(searchUrl)
        ).data;

        return allTreatments;
    }

    public async getTreatmentsForBolusAnalyse(
        date: Date
    ): Promise<TreatmentDto[]> {
        const nextDay = moment(date);
        nextDay.add('days', 2);
        const prevDay = moment(date);
        prevDay.subtract('days', 1);
        const from = moment.utc(prevDay).toISOString();
        const to = moment.utc(nextDay).toISOString();
        let searchUrl = `treatments.json?find[created_at][$gte]=${from}&find[created_at][$lt]=${to}&count=10000`;
        if (this.apiSecret) {
            searchUrl += `&token=${this.apiSecret}`;
        }

        const allTreatments = (
            await this.getAxiosClient(this.baseUrl).get(searchUrl)
        ).data;

        const bgAffectingTreatments = allTreatments.filter(
            (x: TreatmentDto) => x.insulin || x.carbs
        );

        return bgAffectingTreatments;
    }

    private getAxiosClient(baseUrl: string | undefined) {
        if (!baseUrl) {
            throw 'No nightscout url defined';
        }

        return axios.create({
            baseURL:
                (baseUrl.endsWith('/') ? baseUrl : baseUrl + '/') + 'api/v1',
        });
    }
}
