import React from 'react';
import { useData } from '../../../contexts/data.context';
import { getReadingsInTimeRange } from '../../../helper/filter.helper';
import {
    areTreatmentsOverlapping,
    getTreatmentEnd,
} from '../../../helper/insulin-duration.helper';
import { getSegmentTimeOfDay } from '../../../helper/segment-time-helper';
import BolusSegmentModel from '../../../models/bolus-segment.model';
import ReadingModel from '../../../models/dtos/reading.model';
import BolusModel from '../../../models/dtos/treatment.model';
import SegmentRangeModel from '../../../models/segment-range.model';
import BolusSegmentView from '../BolusSegmentView/BolusSegmentView';
import './BolusCurrentSegmentView.scss';

interface BolusCurrentSegmentViewProps {
    readings: ReadingModel[];
    treatments: BolusModel[];
    segment: SegmentRangeModel;
}

function BolusCurrentSegmentView(
    props: BolusCurrentSegmentViewProps
): JSX.Element {
    const { insulinDuration } = useData();

    const getTimeSegments = () => {
        if (props.readings.length === 0) {
            return [];
        }

        const segments: BolusSegmentModel[] = [];

        const sortedByGlucoseLevel = [...props.readings];
        sortedByGlucoseLevel.sort((a, b) => a.glucoseValue - b.glucoseValue);

        const mealtreatments = props.treatments.filter((x) => {
            if (!x.insulin || !x.carbs) return false;

            const { start, end } = getSegmentTimeOfDay(
                x.localDate,
                props.segment
            );

            return x.localDate >= start.toDate() && x.localDate <= end.toDate();
        });

        mealtreatments.sort(
            (a, b) => b.localDate.getTime() - a.localDate.getTime()
        );

        mealtreatments.forEach((treatment) => {
            const startOfSegment = treatment.localDate;
            const endOfSegment = getTreatmentEnd(treatment, insulinDuration);

            const readingsOfSegment = getReadingsInTimeRange(
                props.readings,
                startOfSegment,
                endOfSegment
            );

            const overlappingTreatments = props.treatments.filter((x) =>
                areTreatmentsOverlapping(treatment, x, insulinDuration)
            );

            let minGlucoseValue =
                sortedByGlucoseLevel.length > 0
                    ? sortedByGlucoseLevel[0].glucoseValue
                    : 30;

            const maxGlucoseValue =
                sortedByGlucoseLevel.length > 0
                    ? sortedByGlucoseLevel[sortedByGlucoseLevel.length - 1]
                          .glucoseValue + 15
                    : 200;

            const barHeight = (maxGlucoseValue - minGlucoseValue) / 4 + 10;
            minGlucoseValue -= barHeight;

            segments.push({
                start: startOfSegment,
                end: endOfSegment,
                readings: readingsOfSegment,
                treatment: treatment,
                overlappingTreatments: overlappingTreatments,
                minValue: minGlucoseValue,
                maxValue: maxGlucoseValue,
            });
        });

        return segments;
    };

    if (!props.segment) {
        return <></>;
    }

    const segments = getTimeSegments();

    return (
        <div className="current-date-view">
            <h2>Mahlzeiten-Bolus Analyse</h2>
            <p>
                Hier werden alle deine Mahlzeiten-Boli aufgelistet um die
                Auswirkung von Insulin und Kohlenhydraten zum Ende des aktiven
                Insulins darzustellen.
            </p>
            <ul>
                {segments.map((segment) => (
                    <li key={segment.start.toString()}>
                        <BolusSegmentView segment={segment} />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default BolusCurrentSegmentView;
